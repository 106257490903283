<template>
  <div id="left11" :class="{ left11: $route.query.type == 2 }">
    <span
      class="fs-xl mx-2 ms_title"
      style="font-size: 0.25rem"
      v-if="area1ShowType != 2"
      >{{
        area1ShowType == 4
          ? standBook[area1StandingType - 1].name
          : "商户销量排行"
      }}</span
    >
    <!-- <dv-capsule-chart
      :config="config"
      class="chart"
      v-if="area1ShowType == 1"
    /> -->
    <div
      id="chartDom"
      style="height: 2.5rem; width: 100%; padding-top: 0.1rem"
      v-if="area1ShowType == 1"
    ></div>
    <!--轮播广告-->
    <div
      style="width: 5.6rem; height: 2.6rem; margin: 0 auto; overflow: hidden"
      v-if="area1ShowType == 2"
    >
      <image-tip
        :swiperData="area1Image"
        :imgWidth="imgWidth"
        :imgHeight="imgHeight"
      />
    </div>
    <!--记录-->
    <div
      class="d-flex jc-center body-box"
      style="overflow: hidden"
      v-if="area1ShowType == 4"
    >
      <dv-scroll-board
        :config="config"
        style="height: 2.2rem; margin: 0 auto"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";
import ImageTip from "@/components/echart/image";
import echartMixins from "@/utils/resizeMixins";

export default {
  props: {
    area1Image: {
      default: [],
      type: Array,
    },
    area1StandingType: {
      default: 1,
      type: Number,
    },
    area1ShowType: {
      default: 1,
      type: Number,
    },
    area1_video: {
      default: "",
      type: String,
    },
    isInter: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [echartMixins],
  components: { ImageTip },
  data() {
    return {
      config: {
        data: [],
      },
      imgWidth: "5.6rem",
      imgHeight: "2.6rem",
      standBook: [
        { id: 1, name: "质检记录" },
        { id: 2, name: "进货记录" },
        { id: 3, name: "消杀记录" },
        { id: 4, name: "消毒记录" },
        { id: 5, name: "衡器检修" },
      ],
      standBookHead1: ["时间", "品名", "检测项目", "检测结果"],
      standBookHead2: ["时间", "进场品名", "商户", "进场数量"],
      standBookHead3: ["时间", "消毒区域", "消毒方式", "操作人"],
      standBookHead4: ["时间", "消毒区域", "消杀方式", "操作人"],
      standBookHead5: ["时间", "设备号", "商户", "设备厂家"],
      // uuid: "f8a9737f-edf7-3fd2-bc61-5318ca8705ff",
      uuid: "",
    };
  },
  mounted() {
    this.timer();
    this.init();
    // this.getList();
  },
  methods: {
    getList() {
      if (this.area1ShowType == 1) {
        this.getRechartData();
      } else if (this.area1ShowType == 4) {
        this.getTableList();
      }
    },
    // 获取之前的图表的数据
    getRechartData() {
      axios
        .post("api/screen/order/report", {
          action: "merchant.list",
          limit: 7,
          uuid: this.uuid,
          // uuid: "513ea520-78c5-3351-8e51-4bfaf14198a9",
        })
        .then((res) => {
          const {
            data: { data },
          } = res;

          const target = data.map((c) => {
            const { orgz_name, order_num } = c;
            return {
              name: orgz_name,
              value: order_num,
            };
          });
          let targetLable = target.map((ele) =>
            ele.name.length > 5 ? ele.name.slice(0, 5) + "..." : ele.name
          );
          let targetValue = target.map((ele) => ele.value);
          var colors = [
            "#4587E7",
            "#35AB33",
            "#F5AD1D",
            "#ff7f50",
            "#da70d6",
            "#32cd32",
            "#6495ed",
          ];
          this.chart = this.$echarts.init(document.getElementById("chartDom"));
          this.chart.setOption({
            legend: {
              show: false,
            },
            grid: {
              top: 0,
              left: "3%",
              right: "3%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "value",
              axisLabel: {
                show: true,
                color: "#ffffff",
                fontSize: 12,
              },
              axisLine: {
                show: false,
              },
              splitLine: {
                //设置条纹显示 柱状图默认yAxis(true)
                show: true,
                //设置lineStyle即可写入样式即可
                lineStyle: {
                  //这里输入线条的样式
                  color: "#eeeeee",
                },
              },
            },
            yAxis: {
              type: "category",
              data: targetLable,
              axisLabel: {
                show: true,
                color: "#ffffff",
                fontSize: 12,
                interval: 0,
                rotate: 40, //此行与上一行作用在于使得X轴坐标倾斜，避免X轴坐标较多无法显示
              },
              axisLine: {
                show: false,
              },
            },
            series: [
              {
                type: "bar",
                data: targetValue,
                // colorBy: "data",
                itemStyle: {
                  color: function(params) {
                    // 通过返回值的下标一一对应将颜色赋给柱子上，return出去什么颜色就是什么颜色，这里可以写判断
                    if (targetLable[0].indexOf(params.name) != -1) {
                      return colors[0];
                    } else if (targetLable[1].indexOf(params.name) != -1) {
                      return colors[1];
                    } else if (targetLable[2].indexOf(params.name) != -1) {
                      return colors[2];
                    } else if (targetLable[3].indexOf(params.name) != -1) {
                      return colors[3];
                    } else if (targetLable[4].indexOf(params.name) != -1) {
                      return colors[4];
                    } else if (targetLable[5].indexOf(params.name) != -1) {
                      return colors[5];
                    } else if (targetLable[6].indexOf(params.name) != -1) {
                      return colors[6];
                    }
                    // return colors[params.dataIndex];
                  },
                },
              },
            ],
          });
        });
    },
    // 获取表格的数据
    getTableList() {
      axios
        .post("api/screen/order/report", {
          action: "standing.get",
          uuid: this.uuid,
          type: this.area1StandingType,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          const isFont = this.$route.query.type;

          const target = data.map((c) => {
            const { value1, value2, value3, value4, value5 } = c;
            let aa = value5 ? value4 + value5 : value4;
            let time = formatTime(value1 * 1000, "MM-dd");
            return isFont == 2
              ? [
                  '<span style="font-size:0.3rem">' + time + "</span>",
                  '<span style="font-size:0.3rem">' + value2 + "</span>",
                  '<span style="font-size:0.3rem">' + value3 + "</span>",
                  '<span style="font-size:0.3rem">' + aa + "</span>",
                ]
              : [
                  '<span style="font-size:0.2rem">' + time + "</span>",
                  '<span style="font-size:0.2rem">' + value2 + "</span>",
                  '<span style="font-size:0.2rem">' + value3 + "</span>",
                  '<span style="font-size:0.2rem">' + aa + "</span>",
                ];
          });
          this.config.data = target;

          let tabHead =
            this.area1StandingType == 1
              ? this.standBookHead1
              : this.area1StandingType == 2
              ? this.standBookHead2
              : this.area1StandingType == 3
              ? this.standBookHead3
              : this.area1StandingType == 4
              ? this.standBookHead4
              : this.standBookHead5;

          this.config = {
            data: target,
            header: tabHead,
            rowNum: 5, //表格行数
            headerHeight: 35,
            headerBGC: "rgb(4, 7, 138, 0)", //表头
            oddRowBGC: "rgb(4, 7, 138, 0)", //奇数行
            evenRowBGC: "rgb(4, 7, 138, 0)", //偶数行
            align: ["center", "center", "center", "center", "center"],
          };
        });
    },
    timer() {
      return setInterval(() => {
        this.getList();
      }, 60000);
    },
    async init() {
      if (window.android && !this.isInter) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getList();
    },
  },
};
</script>

<style lang="scss">
#left11 {
  padding: 0.5rem 0.2rem 0.3rem;
  height: 3.25rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  overflow: hidden;

  background: url("../../assets/list_bac.png") no-repeat;
  background-size: 100% 100%;
  .dv-scroll-board .header {
    border: 1px solid #4a60fb !important;
    color: #64d6ff !important;
    background: transparent !important;
  }

  .ms_title {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    height: 0.5rem;
    line-height: 0.5rem;
    padding-left: 0.1rem;
  }

  .ms_title::before {
    content: " ";
    display: inline-block;
    width: 0.05rem;
    height: 0.15rem;
    margin-right: 0.1rem;
    background-color: #64ffa3;
  }

  .dv-capsule-chart {
    margin-top: 10px;
  }
  .dv-capsule-chart .capsule-item div {
    // height: 14px;
  }
  .dv-capsule-chart .label-column div {
    height: 24px;
  }
  .dv-capsule-chart .capsule-item {
    -webkit-box-shadow: 0 0 3px #999;
    box-shadow: 0 0 3px #999;
    height: 10px;
    margin: 7px 0px;
    border-radius: 5px;
  }
  // .dv-capsule-chart .unit-label {
  //   display: flex;
  // }
  .dv-capsule-chart .label-column {
    font-size: 12px;
  }
  .dv-capsule-chart .capsule-container {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: flex-start;
  }
}
.left11 .dv-capsule-chart .label-column {
  font-size: 0.3rem !important;
}
.left11 .dv-capsule-chart .label-column div {
  height: 0.0125rem !important;
  margin-bottom: 0.5rem !important;
}

.left11 .dv-capsule-chart .capsule-item {
  margin-top: 0.025rem !important;
  margin-bottom: 0.35rem !important;
}
.left11 .dv-capsule-chart .unit-label {
  display: none;
}
</style>
