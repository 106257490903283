<template>
  <div id="index1">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <div
            style="display:flex;justify-content:space-between;width:100%;align-items:center;padding:0 0.25rem"
          >
            <div style="width:8rem;display:flex;align-items:center">
              <span
                style="font-size: 0.3rem;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #FFFFFF;margin-right:0.05rem"
                >{{ debug_city }}</span
              >
              <span
                style="font-size: 0.3rem;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #FFFFFF;"
              >
                {{ local.temperature }} ℃
              </span>
              <span
                style="font-size: 0.25rem;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #FFFFFF;margin-left:0.2rem;margin-right:0.05rem"
              >
                {{ local.type }}
              </span>
              <span>|</span>
              <span
                style="font-size:0.25rem;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #FFFFFF;margin-left:0.05rem"
              >
                {{ local.fengxiang }}
              </span>
            </div>
            <div class="top_head">{{ market_name }}</div>

            <div class="text" :class="{ weekStyle: $route.query.type == 2 }">
              {{ dateYear }} {{ dateWeek }} {{ dateDay }}
            </div>
          </div>
        </div>

        <!-- 第二行  没了-->
        <div style="margin-bottom:0.2rem">
          <dv-decoration-2
            style="height:10px"
            :color="['#4A60FB', '#4A60FB']"
          />
        </div>
        <!-- 第三行 -->
        <div class="top">
          <div class="one">
            <div v-if="sale_money_show">今日交易额</div>
            <div v-if="sale_num_show">今日交易笔数</div>
            <div v-if="merchant_num_show">商户数量</div>
            <div v-if="flow_num_show">客流量</div>
            <div v-if="inspection_num_show">本月巡查</div>
            <div v-if="mouth_quality_num_show">本月检测</div>
            <div v-if="qualified_rate_show">质检合格率</div>
            <div v-if="floor_num_show">铺位数</div>
            <div v-if="week_order_num_show">周交易笔数</div>
            <div v-if="month_order_num_show">
              月交易笔数
            </div>
            <div v-if="week_order_amount_show">
              周交易额
            </div>
            <div v-if="month_order_amount_show">
              月交易额
            </div>
            <div v-if="week_flow_num_show">
              周客流量
            </div>
            <div v-if="month_flow_num_show">
              月客流量
            </div>
            <div v-if="weighting_num_show">
              销售量
            </div>
            <div v-if="weighting_amount_show">
              销售金额
            </div>
            <div v-if="day_quality_num_show">
              当日检测
            </div>
            <div v-if="week_quality_num_show">
              本周检测
            </div>
            <div v-if="sale_rate_show">
              日均客单价
            </div>
            <div v-if="week_sale_rate_show">
              周均客单价
            </div>
            <div v-if="month_sale_rate_show">
              月均客单价
            </div>
          </div>
          <div class="two">
            <div v-if="sale_money_show">
              {{ sale_money }}<span class="font36">元</span>
            </div>
            <div v-if="sale_num_show">
              {{ sale_num }}<span class="font36">笔</span>
            </div>
            <div v-if="merchant_num_show">
              {{ merchant_num }}<span class="font36">个</span>
            </div>
            <div v-if="flow_num_show">
              {{ flow_num }}<span class="font36">人</span>
            </div>
            <div v-if="inspection_num_show">
              {{ inspection_num }}<span class="font36">次</span>
            </div>
            <div v-if="mouth_quality_num_show">
              {{ mouth_quality_num }}<span class="font36">次</span>
            </div>
            <div v-if="qualified_rate_show">
              {{ qualified_rate }}<span class="font36">%</span>
            </div>
            <div v-if="floor_num_show">
              {{ floor_num }}<span class="font36">个</span>
            </div>
            <div v-if="week_order_num_show">
              {{ week_order_num }}<span class="font36">笔</span>
            </div>
            <div v-if="month_order_num_show">
              {{ month_order_num }}<span class="font36">笔</span>
            </div>
            <div v-if="week_order_amount_show">
              {{ week_order_amount }}<span class="font36">元</span>
            </div>
            <div v-if="month_order_amount_show">
              {{ month_order_amount }}<span class="font36">元</span>
            </div>
            <div v-if="week_flow_num_show">
              {{ week_flow_num }}<span class="font36">人</span>
            </div>
            <div v-if="month_flow_num_show">
              {{ month_flow_num }}<span class="font36">人</span>
            </div>
            <div v-if="weighting_num_show">
              {{ weighting_num }}<span class="font36">kg</span>
            </div>
            <div v-if="weighting_amount_show">
              {{ weighting_amount }}<span class="font36">元</span>
            </div>
            <div v-if="day_quality_num_show">
              {{ day_quality_num }}<span class="font36">次</span>
            </div>
            <div v-if="week_quality_num_show">
              {{ week_quality_num }}<span class="font36">次</span>
            </div>
            <div v-if="sale_rate_show">
              {{ sale_rate }}<span class="font36">元</span>
            </div>
            <div v-if="week_sale_rate_show">
              {{ week_sale_rate }}<span class="font36">元</span>
            </div>
            <div v-if="month_sale_rate_show">
              {{ month_sale_rate }}<span class="font36">元</span>
            </div>
          </div>
        </div>

        <div class="body-box">
          <!-- 第三行数据 -->
          <div
            class="content-box clearfix"
            style="position:relative;width:100%"
          >
            <div class="leftFirst" style="position:relative;width:25%">
              <!-- 左边 -->
              <div>
                <!--业态占比-->
                <left1
                  :area1ShowType="global_data.area1_show_type"
                  :area1Image="
                    global_data.area1_image ? global_data.area1_image : []
                  "
                  :area1StandingType="global_data.area1_standing_type"
                  :isInter="isInter"
                />
              </div>
              <div style="position:relative;width:100%;margin-top:0.1rem;">
                <!--质检公示-->
                <!--<dv-border-box-13>-->
                <mid2_1
                  :area1ShowType="global_data.area2_show_type"
                  :area1Image="
                    global_data.area2_image ? global_data.area2_image : []
                  "
                  :area1StandingType="global_data.area2_standing_type"
                  :isInter="isInter"
                />
                <!-- <left2 /> -->
                <!--</dv-border-box-13>-->
              </div>
            </div>
            <!-- 中间 -->
            <div class="middleFirst" style="position:relative;width:50%">
              <bottomLeft :isInter="isInter" />
            </div>
            <!-- 右边 -->
            <div class="rightFirst" style="position:relative;width:25%">
              <div>
                <!-- <dv-border-box-13> -->
                <!-- 今日销售商品排行 -->
                <right1_1
                  ref="right1_1"
                  :areaShowType="global_data.area7_show_type"
                  :areaImage="
                    global_data.area7_image ? global_data.area7_image : []
                  "
                  :areaStandingType="global_data.area7_standing_type"
                  :isInter="isInter"
                />
                <!-- </dv-border-box-13> -->
              </div>
              <div style="position:relative;margin-top:0.1rem;">
                <!--实时交易-->
                <!-- <dv-border-box-13> -->
                <right3
                  :area1ShowType="global_data.area6_show_type"
                  :area1Image="
                    global_data.area6_image ? global_data.area6_image : []
                  "
                  :area1StandingType="global_data.area6_standing_type"
                  :isInter="isInter"
                />
                <!-- </dv-border-box-13> -->
              </div>
            </div>
          </div>
          <!-- 第四行数据 -->
          <div class="bototm-box" style="width:100%">
            <div style="position:relative;width:25%;">
              <!--检测项目占比-->
              <left3_1
                :area1ShowType="global_data.area3_show_type"
                :area1Image="
                  global_data.area3_image ? global_data.area3_image : []
                "
                :area1StandingType="global_data.area3_standing_type"
                :isInter="isInter"
              />
            </div>
            <div class="list" style="position:relative;width:50%;">
              <!--特价公示-->
              <div
                style="padding-left:0.1rem;padding-right: 0.1rem;display:flex;"
              >
                <!-- <dv-border-box-13> -->
                <div style="position:relative;width:100%;margin-right:0.05rem;">
                  <mid1
                    :area1ShowType="global_data.is_on_sale_type"
                    :area1Image="
                      global_data.on_sale_image ? global_data.on_sale_image : []
                    "
                    :area1StandingType="global_data.area1_standing_type"
                    :area1Video="global_data.on_sale_video"
                    :market_id="market_id"
                    :isInter="isInter"
                  />
                </div>
                <!-- </dv-border-box-13> -->
                <!--品类分布-->
                <!-- <dv-border-box-13> -->
                <div style="position:relative;width:100%;margin-left:0.05rem;">
                  <right2
                    :area1ShowType="global_data.area4_show_type"
                    :area1Image="
                      global_data.area4_image ? global_data.area4_image : []
                    "
                    :area1StandingType="global_data.area4_standing_type"
                    :market_id="market_id"
                    :isInter="isInter"
                  />
                </div>
                <!-- </dv-border-box-13> -->
              </div>
            </div>
            <div style="position:relative;width:25%;">
              <!--商户销量排行-->
              <!-- <dv-border-box-13> -->
              <div style="position:relative;width:100%;">
                <mid2
                  :area1ShowType="global_data.area5_show_type"
                  :area1Image="
                    global_data.area5_image ? global_data.area5_image : []
                  "
                  :area1StandingType="global_data.area5_standing_type"
                  :isInter="isInter"
                />
              </div>
              <!-- </dv-border-box-13> -->
            </div>
          </div>
        </div>
      </div>
      <div
        style="font-size:0.175rem;height:0.3rem;line-height:0.6rem;text-align:center;color:#64D6FF"
      >
        <marquee v-if="global_data.is_show_notice == 1" direction="left">{{
          notice
        }}</marquee>
        <div v-if="global_data.is_show_notice == 0">
          上海传蔬信息提供技术支持 电话：400-135-9365
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "../../utils/index.js";
import bottomLeft from "./bottomLeft";
import left1 from "./left1";
import left3_1 from "./left3_1";
import mid1 from "./mid1";
import mid2 from "./mid2";
import mid2_1 from "./mid2_1";

import right1_1 from "./right1_1";
import right2 from "./right2";
import right3 from "./right3";
export default {
  data() {
    return {
      merchant_num_show: false,
      sale_money_show: false,
      sale_num_show: false,
      flow_num_show: false,
      mouth_quality_num_show: false,
      inspection_num_show: false,
      qualified_rate_show: false,
      floor_num_show: false,
      week_order_num_show: false,
      month_order_num_show: false,
      week_order_amount_show: false,
      month_order_amount_show: false,
      week_flow_num_show: false,
      month_flow_num_show: false,
      weighting_num_show: false,
      weighting_amount_show: false,
      day_quality_num_show: false,
      week_quality_num_show: false,
      sale_rate_show: false,
      week_sale_rate_show: false,
      month_sale_rate_show: false,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      global_data: {}, //全局数据
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      market_name: "",
      uuid: "",
      type: "",
      notice: "",
      sale_money: "",
      sale_num: "",
      merchant_num: "",
      flow_num: "",
      mouth_quality_num: "",
      inspection_num: "",
      qualified_rate: "",
      show: false,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight, //实时屏幕高度
      ip: "",
      local: {
        // ip地址所在城市
        city: "",
        // 所在城市温度
        temperature: "",
        // 天气类型
        type: "",
        province: "",
      },
      debug_city: "",
      market_id: "",
      timeFns: "", //倒计时
      topInitInterval: "", //大的倒计时
    };
  },
  props: {
    isInter: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    bottomLeft,
    left1,
    // left2,
    left3_1,
    mid1,
    mid2,
    mid2_1,
    // right1,
    right1_1,
    right2,
    right3,
  },
  mounted() {
    this.timeFn();
    // this.init();
    this.getLocalCity();
    setInterval(() => {
      this.init();
    }, 300000);
  },
  methods: {
    getLocalCity() {
      var data = {
        key: "72IBZ-SVTRQ-QPR5H-GSIUJ-T3ZOO-AZF2H", //申请的密钥,写死就行
      };
      var url = "https://apis.map.qq.com/ws/location/v1/ip"; //这个就是地理位置信息的接口
      data.output = "jsonp";
      this.$jsonp(url, data)
        .then((res) => {
          this.local.city = res.result.ad_info.city;
          this.local.province = res.result.ad_info.province;
          // this.debug_city = res.result.ad_info.city;
          // 根据城市，获取当前天气和时间（在此先预留获取天气方法，完成后取消注释）
          this.getLocalWeather(this.local.city);
          return res;
        })
        .catch(() => {});
    },
    //腾讯天气
    getLocalWeather(city) {
      this.debug_city = city;
      var data = {
        source: "pc",
        weather_type: "forecast_1h",
        province: this.local.province,
        city: this.local.city,
      };
      var url = "https://wis.qq.com/weather/common";
      data.output = "jsonp";
      this.$jsonp(url, data)
        .then((res) => {
          this.local.temperature = res.data.forecast_1h[0].degree;
          this.local.type = res.data.forecast_1h[0].weather;
          this.local.fengxiang = res.data.forecast_1h[0].wind_direction;
          // this.local.city = res.result.ad_info.city;
          // this.getLocalWeather(this.local.city);
          return res;
        })
        .catch(() => {});
    },

    timeFn() {
      let that = this;
      clearInterval(that.timeFns);
      this.timeFns = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    async init() {
      if (window.android && !this.isInter) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
        this.type = qs["type"];
        this.getTopServe();
        this.getMarketTitle();
      } else {
        this.uuid = this.$route.query.uuid;
        this.type = this.$route.query.type;
        this.getTopServe();
        this.getMarketTitle();
      }
    },
    // async topInit() {
    //   if (window.android) {
    //     var qs = await JSON.parse(window.android.getBindInfo());
    //     this.uuid = qs["uuid"];
    //   } else {
    //     this.uuid = this.$route.query.uuid;
    //   }
    //   let that = this;
    //   clearInterval(that.topInitInterval);
    //   this.topInitInterval = setInterval(() => {
    //     this.getTopServe();
    //   }, 60000);
    // },
    getTopServe() {
      axios
        .post("api/screen/order/report", {
          action: "top.get",
          uuid: this.uuid,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.sale_money = parseInt(res.data.data.sale_money / 100);
            this.sale_num = res.data.data.sale_num;
            this.merchant_num = res.data.data.merchant_num;
            this.flow_num = res.data.data.flow_num;
            this.inspection_num = res.data.data.inspection_num;
            this.mouth_quality_num = res.data.data.mouth_quality_num;
            this.qualified_rate = res.data.data.qualified_rate;
            this.floor_num = res.data.data.floor_num;
            this.week_order_num = res.data.data.week_order_num;
            this.month_order_num = res.data.data.month_order_num;
            this.week_order_amount = parseInt(
              res.data.data.week_order_amount / 100
            );
            this.month_order_amount = parseInt(
              res.data.data.month_order_amount / 100
            );
            this.week_flow_num = res.data.data.week_flow_num;
            this.month_flow_num = res.data.data.month_flow_num;
            this.weighting_num = res.data.data.weighting_num;
            this.weighting_amount = parseInt(
              res.data.data.weighting_amount / 100
            );
            this.day_quality_num = res.data.data.day_quality_num;
            this.week_quality_num = res.data.data.week_quality_num;
            this.sale_rate = parseInt(res.data.data.sale_rate / 100);
            this.week_sale_rate = parseInt(res.data.data.week_sale_rate / 100);
            this.month_sale_rate = parseInt(
              res.data.data.month_sale_rate / 100
            );
          }
        });
    },
    getMarketTitle() {
      console.log("查看调用次数");
      axios
        .post("api/screen/client/main", {
          action: "market.common.get",
          type: this.type,
          uuid: this.uuid,
        })
        .then((res) => {
          console.log("查看请求次数");
          if (res.data.code == 0) {
            let objData = res.data.data;
            this.market_id = objData.market_id;
            objData.area1_image = objData.area1_image
              ? objData.area1_image
              : [];
            objData.area2_image = objData.area2_image
              ? objData.area2_image
              : [];
            objData.area3_image = objData.area3_image
              ? objData.area3_image
              : [];
            objData.area4_image = objData.area4_image
              ? objData.area4_image
              : [];
            objData.area5_image = objData.area5_image
              ? objData.area5_image
              : [];
            objData.area6_image = objData.area6_image
              ? objData.area6_image
              : [];
            objData.area7_image = objData.area7_image
              ? objData.area7_image
              : [];
            this.global_data = objData;
            console.log(this.global_data,"this.global_data")
            this.market_name = objData.market_name;
            this.notice = objData.notice;
            this.top_show = objData.top_show;
            let top_title = this.top_show;
            top_title.forEach(function(element) {
              if (element == 1) {
                this.sale_money_show = true;
              } else if (element == 2) {
                this.sale_num_show = true;
              } else if (element == 3) {
                this.merchant_num_show = true;
              } else if (element == 4) {
                this.flow_num_show = true;
              } else if (element == 5) {
                this.inspection_num_show = true;
              } else if (element == 6) {
                this.mouth_quality_num_show = true;
              } else if (element == 7) {
                this.qualified_rate_show = true;
              } else if (element == 8) {
                this.floor_num_show = true;
              } else if (element == 9) {
                this.week_order_num_show = true;
              } else if (element == 10) {
                this.month_order_num_show = true;
              } else if (element == 11) {
                this.week_order_amount_show = true;
              } else if (element == 12) {
                this.month_order_amount_show = true;
              } else if (element == 13) {
                this.week_flow_num_show = true;
              } else if (element == 14) {
                this.month_flow_num_show = true;
              } else if (element == 15) {
                this.weighting_num_show = true;
              } else if (element == 16) {
                this.weighting_amount_show = true;
              } else if (element == 17) {
                this.day_quality_num_show = true;
              } else if (element == 18) {
                this.week_quality_num_show = true;
              } else if (element == 19) {
                this.sale_rate_show = true;
              } else if (element == 20) {
                this.week_sale_rate_show = true;
              } else if (element == 21) {
                this.month_sale_rate_show = true;
              }
            }, this);
          }

          this.cancelLoading();
        });
    },
    link() {
      this.show = !this.show;
    },
    changeScreen() {
      this.$router.push({ name: "index" });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/index1.scss";
@import url("../../assets/font/font.css");
@media screen and (max-device-width: 1080px) {
  .dv-scroll-board .header .header-item {
    font-size: 0.2rem;
    height: 0.45rem !important;
    line-height: 0.45rem !important;
    transform: scale(0.7);
    -ms-transform: scale(0.7);
  }
  .ms_title {
    font-size: 0.25rem;
    transform: scale(0.7);
    -ms-transform: scale(0.7);
    padding-left: 0;
    margin-left: 0;
  }
  .dv-scroll-board .rows .ceil {
    padding: 0;
    font-size: 0.2rem;
    transform: scale(0.7);
    -ms-transform: scale(0.7);
  }
  .dv-scroll-board .rows .row-item {
    height: 0.36rem !important;
    line-height: 0.36rem !important;
  }
  .dv-scroll-board .rows {
    height: auto !important;
  }
}
@media (min-device-width: 1080px) and (max-device-width: 1280px) {
  .dv-scroll-board .header .header-item {
    font-size: 0.2rem;
    height: 0.45rem !important;
    line-height: 0.45rem !important;
    transform: scale(0.85);
    -ms-transform: scale(0.85);
  }
  .ms_title {
    font-size: 0.25rem;
    transform: scale(0.85);
    -ms-transform: scale(0.85);
    padding-left: 0;
    margin-left: 0;
  }
  .dv-scroll-board .rows .ceil {
    padding: 0;
    font-size: 0.2rem;
    transform: scale(0.85);
    -ms-transform: scale(0.85);
  }
  .dv-scroll-board .rows .row-item {
    height: 0.36rem !important;
    line-height: 0.36rem !important;
  }
  .dv-scroll-board .rows {
    height: auto !important;
  }
}
// @media screen and (min-device-width: 1280px) {
//       .dv-scroll-board .header .header-item {
//     font-size: 0.25rem;
//   }
//   .ms_title{
//     font-size:0.25rem;
//   }
//   .dv-scroll-board .rows .ceil {
//     font-size: 0.25rem;
//   }
// }
#index1 {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  background-image: url("../../assets/bg.png");
  background-size: cover;
  background-position: center center;
}

.bg {
  padding: 0.2rem 0.2rem 0 0.2rem;
}
.dv-scroll-board .header .header-item {
  padding: 0;
}
.content-list {
  padding: 0.3rem 0.2rem;
}
.list-bac {
  background: url(../../assets/list_bac.png) no-repeat;
  background-size: 100% 100%;
}
.main-bac {
  background: url(../../assets/main_bac.png) no-repeat;
  background-size: 100% 100%;
}

.top_head {
  background: url("../../assets/head.png") no-repeat;
  background-size: 100% 0.945rem;
  line-height: 0.825rem;
  text-align: center;
  // width: 8rem;
  width: 100%;
  height: 0.95rem;
  font-size: 0.35rem;
  font-weight: bold;
}
.font36 {
  font-size: 0.4rem;
}
.third_content {
  width: 2.875rem;
  height: 1.25rem;
  background: rgba(0, 22, 255, 0.17);
  box-shadow: 0px 1px 3px 0px rgba(74, 103, 255, 0.5), 0px -1px 3px 0px #4a67ff;
  border-radius: 8px;
  .head {
    font-size: 0.2rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #64d6ff;
    margin-top: 0.075rem;
    text-align: center;
  }
  .head_bg {
    width: 2.7rem;
    height: 0.825rem;
    background: url("../../assets/bg_line.png");
    background-size: 3.3rem 0.825rem;
    margin: 0.05rem auto;
    font-size: 48px;
    font-family: "digitalRegular";
    font-weight: 400;
    color: #64ffa3;
    line-height: 0.825rem;
    text-align: center;
  }
}
.top {
  width: 100%;
  background: #0004b0;
  height: 1.1rem;
  .one {
    font-size: 0.35rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #64d6ff;
    display: flex;
    justify-content: space-between;
    height: 0.45rem;
    line-height: 0.45rem;
    // padding-left:  12px;
    div {
      // width:170px;
      width: 100%;
      text-align: center;
    }
  }
  .two {
    background: #030098;
    width: 100%;
    height: 0.65rem;
    line-height: 0.65rem;
    display: flex;
    justify-content: space-between;
    font-size: 0.65rem;
    font-family: LetsgoDigital-Regular, LetsgoDigital;
    font-weight: 400;
    color: #64ffa3;

    div {
      // width: 12.5%;
      width: 100%;
      text-align: center;
    }
  }
}
.text {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #13daec;
  width: 8rem;
  text-align: right;
}
.weekStyle {
  font-size: 0.35rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #13daec;
  width: 8rem;
  text-align: right;
}
</style>
