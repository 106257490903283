<template>
  <div style="padding-left:0.1rem;padding-right:0.1rem;height:100%">
    <div id="bottomLeft">
      <div class="bg-color-black">
        <div
          class="pt-2 pl-2"
          style="text-align:center;"
          v-if="is_show_sale_video_url == 0"
        >
          <span class="text mx-2" style="font-size:0.25rem;">销量/客流</span>
        </div>
        <div>
          <BottomLeftChart v-if="is_show_sale_video_url == 0" />
          <video-player
            :options="videoOptions"
            style="overflow: hidden;height:6.1rem"
            v-if="is_show_sale_video_url == 1"
            ref="video"
          />
          <!--轮播广告-->
          <div
            style="width:11rem;height:6rem;margin:0 auto;overflow: hidden;padding-top:0.6rem"
            v-if="is_show_sale_video_url == 2"
          >
            <image-tip
              :swiperData="area1Image"
              :imgWidth="imgWidth"
              :imgHeight="imgHeight"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BottomLeftChart from "@/components/echart/bottom/bottomLeftChart";
import VideoPlayer from "@/components/echart/video";
import ImageTip from "@/components/echart/image";
import axios from "axios";

export default {
  data() {
    return {
      imgWidth: "11",
      imgHeight: "6.0625",
      videoOptions: {
        autoplay: "muted", //自动播放
        controls: true, //用户可以与之交互的控件
        loop: true, //视频一结束就重新开始
        muted: true, //默认情况下将使所有音频静音
        aspectRatio: "16:9", //显示比率
        fullscreen: {
          options: { navigationUI: "hide" },
        },
        sources: [
          {
            // src: require("@/assets/1623139115688185.mp4"),
            src: "",
            type: "video/mp4",
          },
        ],
      },
      is_show_sale_video_url: "",
      uuid: "",
    };
  },
  components: {
    BottomLeftChart,
    VideoPlayer,
    ImageTip,
  },
  props:{
    isInter: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      if (window.android && !this.isInter) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getMarketTitle();
    },
    getMarketTitle() {
      console.log('8888888888888')
      axios
        .post("api/screen/client/main", {
          action: "market.common.get",
          uuid: this.uuid,
          // uuid: "f8a9737f-edf7-3fd2-bc61-5318ca8705ff",
        })
        .then((res) => {
          this.is_show_sale_video_url =
            res.data.data.is_show_sale_video_url * 1;
          this.videoOptions.sources[0].src = res.data.data.sale_video;
          this.area1Image = res.data.data.sale_image;
        });
    },
  },
};
</script>

<style lang="scss">
#bottomLeft {
  padding: 0 0.2rem 0.3rem;
  height: 100%;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  background: url("../../assets/main_bac.png") no-repeat;
  background-size: 100% 100%;

  .bg-color-black {
    height: 6.0625rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }
  // .video-js.vjs-fluid {
  //   margin-top: 0.125rem;
  // }
  // .video-js .vjs-tech {
  //   width: auto;
  // }
}
</style>
