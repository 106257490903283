<template>
  <div id="left1">
    <span
      class="fs-xl mx-2 ms_title"
      style="font-size: 0.25rem"
      v-if="area1ShowType != 2"
      >{{
        area1ShowType == 4
          ? standBook[area1StandingType - 1].name
          : "市场业态占比"
      }}</span
    >
    <!-- <dv-capsule-chart :config="config" class="chart" /> -->
    <!--原来的图表-->
    <div
      id="left_echarts"
      style="height: 2.5rem; width: 5.5rem"
      v-if="area1ShowType == 1"
    ></div>
    <!--轮播广告-->
    <div
      style="width: 5.6rem; height: 2.5rem; margin: 0 auto; overflow: hidden"
      v-if="area1ShowType == 2"
    >
      <image-tip
        :swiperData="area1Image"
        :imgWidth="imgWidth"
        :imgHeight="imgHeight"
      />
    </div>
    <!--记录-->
    <div class="d-flex jc-center body-box" v-if="area1ShowType == 4">
      <dv-scroll-board
        :config="config"
        style="width: 5.4rem; height: 2.2rem; margin: 0 auto"
      />
      <!--<dv-scroll-board :config="config" style="width:4.45rem;height:4.1rem" />-->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";
import ImageTip from "@/components/echart/image";

export default {
  props: {
    area1Image: {
      default: [],
      type: Array,
    },
    area1StandingType: {
      default: 1,
      type: Number,
    },
    area1ShowType: {
      default: 1,
      type: Number,
    },
    area1_video: {
      default: "",
      type: String,
    },
    isInter: {
      type: Boolean,
      default: false,
    },
  },
  components: { ImageTip },
  data() {
    return {
      config: {},
      imgWidth: "5.6rem",
      imgHeight: "2.5rem",
      standBook: [
        { id: 1, name: "质检记录" },
        { id: 2, name: "进货记录" },
        { id: 3, name: "消杀记录" },
        { id: 4, name: "消毒记录" },
        { id: 5, name: "衡器检修" },
      ],
      standBookHead1: ["时间", "品名", "检测项目", "检测结果"],
      standBookHead2: ["时间", "进场品名", "商户", "进场数量"],
      standBookHead3: ["时间", "消毒区域", "消毒方式", "操作人"],
      standBookHead4: ["时间", "消毒区域", "消杀方式", "操作人"],
      standBookHead5: ["时间", "设备号", "商户", "设备厂家"],

      // uuid: "f8a9737f-edf7-3fd2-bc61-5318ca8705ff",
      uuid: "",
    };
  },
  mounted() {
    this.timer();
    this.init();
    // this.getList();
  },
  methods: {
    getList() {
      if (this.area1ShowType == 1) {
        this.getRechartData();
      } else if (this.area1ShowType == 4) {
        this.getTableList();
      }
    },
    // 获取图表的数据
    getRechartData() {
      axios
        .post("api/screen/order/report", {
          action: "floor.get",
          uuid: this.uuid,
          limit: 4,
        })
        .then((res) => {
          const {
            data: { data, code },
          } = res;
          if (code == 0 && data.length > 0) {
            let filterData = data.sort(this.toLarge);
            this.targetData = filterData.map((c, index) => {
              const { money, rate, name } = c;
              return {
                name: name.length > 3 ? name.slice(0, 3) : name,
                trueValue: money / (100).toFixed(2),
                value: money,
                rate: rate,
              };
            });
            this.iniChart();
          }
        });
    },
    toLarge(a, b) {
      return b.money - a.money;
    },
    // 获取表格的数据
    getTableList() {
      axios
        .post("api/screen/order/report", {
          action: "standing.get",
          uuid: this.uuid,
          type: this.area1StandingType,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          const target = data.map((c) => {
            const { value1, value2, value3, value4, value5 } = c;
            let aa = value5 ? value4 + value5 : value4;
            let time = formatTime(value1 * 1000, "MM-dd");
            return [time, value2, value3, aa];
          });
          this.config.data = target;

          let tabHead =
            this.area1StandingType == 1
              ? this.standBookHead1
              : this.area1StandingType == 2
              ? this.standBookHead2
              : this.area1StandingType == 3
              ? this.standBookHead3
              : this.area1StandingType == 4
              ? this.standBookHead4
              : this.standBookHead5;

          this.config = {
            data: target,
            header: tabHead,
            rowNum: 5, //表格行数
            headerHeight: 35,
            headerBGC: "rgb(4, 7, 138, 0)", //表头
            oddRowBGC: "rgb(4, 7, 138, 0)", //奇数行
            evenRowBGC: "rgb(4, 7, 138, 0)", //偶数行
            align: ["center", "center", "center", "center", "center"],
          };
        });
    },
    iniChart() {
      const isFont = this.$route.query.type;
      //放大值
      var pieData = this.targetData.slice(0, 6);
      var showData = [];
      var sum = 0,
        max = 0;
      pieData.forEach((item) => {
        sum += item.value;
        if (item.value >= max) max = item.value;
      });
      // 放大规则
      var number = Math.round(max * 0.9);
      showData = pieData.map((item) => {
        return {
          value: number + item.value,
          name: item.name,
          rate: item.rate,
        };
      });

      this.chart = this.$echarts.init(document.getElementById("left_echarts"));
      this.chart.setOption({
        color: [
          "#10CFE7",
          "#68D9B5",
          "#FBA1AB",
          "#DAB0FF",
          "#8E6FFF ",
          "#6C44FF",
          "#FE7474",
        ],
        tooltip: {
          trigger: "item",
        },

        legend: {
          orient: "vertical",
          top: "center",
          right: 10,
          textStyle: {
            color: "#fff",
          },
          formatter: function(name) {
            // 获取legend显示内容
            let total = 0;
            let tarValue = 0;
            console.log(pieData, "pieData");
            for (let i = 0, l = pieData.length; i < l; i++) {
              total += pieData[i].value;
              if (pieData[i].name == name) {
                tarValue = pieData[i].rate;
              }
            }
            // let p = ((tarValue / total) * 100).toFixed(1);
            let p = tarValue;
            return name + " " + p + "%";
          },
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            center: ["30%", "55%"],
            radius: [0, 80],
            roseType: "radius",
            itemStyle: {
              borderRadius: 5,
            },
            data: showData,
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            // label: {
            //   normal: {
            //     textStyle: {
            //       fontSize: isFont == 2?this.FontChart(0.42):this.FontChart(0.22),
            //     },
            //     formatter: "{b}",
            //   },
            //   x: 0,
            // },
          },
        ],
      });
    },
    // 字体适配
    FontChart(res) {
      //获取到屏幕的宽度
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return; //报错拦截：
      let fontSize = 80 * (clientWidth / 1920);
      return res * fontSize;
    },
    timer() {
      return setInterval(() => {
        this.getList();
      }, 60000);
    },
    async init() {
      if (window.android && !this.isInter) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getList();
    },
  },
};
</script>

<style lang="scss">
#left1 {
  padding: 0.5rem 0.2rem 0.3rem;
  height: 3.25rem;
  // min-width: 3.75rem;
  border-radius: 0.0625rem;
  background: url("../../assets/list_bac.png") no-repeat;
  background-size: 100% 100%;
  .dv-scroll-board .header {
    border: 1px solid #4a60fb !important;
    color: #64d6ff !important;
    background: transparent !important;
  }
  .ms_title {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    height: 0.5rem;
    line-height: 0.5rem;
    padding-left: 0.1rem;
  }
  .ms_title::before {
    content: " ";
    display: inline-block;
    width: 0.05rem;
    height: 0.15rem;
    margin-right: 0.1rem;
    background-color: #64ffa3;
  }
  .body-box {
    overflow: hidden;
  }
  .dv-capsule-chart {
    margin-top: 10px;
  }
  .dv-capsule-chart .capsule-item div {
    // height: 14px;
  }
  .dv-capsule-chart .label-column div {
    height: 24px;
  }
  .dv-capsule-chart .capsule-item {
    -webkit-box-shadow: 0 0 3px #999;
    box-shadow: 0 0 3px #999;
    height: 10px;
    margin: 7px 0px;
    border-radius: 5px;
  }
  .dv-capsule-chart .unit-label {
    display: none;
  }
  .dv-capsule-chart .label-column {
    font-size: 12px;
  }
  .dv-capsule-chart .capsule-container {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: flex-start;
  }
}
</style>
